import Vue from 'vue'
import {
  handleize,
  decode,
  getVariantName,
  getProductFullName,
  mapProductCollectionsToGa4Categories,
  forceRefreshYotpoWidgets,
  deepClone,
  formatPrice
} from "@/lib/util";
import appState from '@/lib/appState'
import { trigger } from '@/lib/utils'
import { formatMoney } from '@shopify/theme-currency'

import ProductCardMixin from './product-card-mixin'
import on from 'dom-event'
import state from '@/lib/appState'
import cart from '@/lib/cart'

/**
 * This component is used throughout the site,
 * in product carousels and on the PLP page.
 */
Vue.component('product-card', {
  mixins: [ProductCardMixin],
  props: [
    'product',
    'decode',
    'featured',
    'stepTitle',
    'currentProductHandle',
    'listAttribution'
  ],

  /**
   * Data can come via a JSON string that needs to be
   * decoded, or via an javascript object, passed by a
   * parent component or a Vuex store.
   */
  data () {
    let product
    if (!!this.decode && this.product) {
      product = JSON.parse(decode(this.product))
    } else {
      product = (this.product || {
        title: '',
        price: '',
        compareAtPrice: '',
        featuredImage: '',
        variants: [],
        firstAvailableVariant: {},
        optionNames: [],
        optionsWithValues: [],
        collection: []
      })
    }

    return {
      ...product,
      isColorSelected: false,
      colorSelected: '',
      selectedOptions: {},
      selectedVariant: {},
      inViewport: false,
      appState,
      showModal: false,
      isProcessing: false,
      contentObserver: null,
      rating: 0,
      reviewCount: 0
    }
  },
  watch: {
    activeColor (value) {
      this.selectedOptions['Color'] = value
    },
    'selectedOptions': {
      handler () {
        const selectedVariant = this.variants.find(variant => {
          return this.optionNames.every((option, index) => {
            return variant[`option${index + 1}`] === this.selectedOptions[option]
          })
        })

        this.selectedVariant = selectedVariant || {}
      },
      deep: true
    }
  },
  computed: {
    getBadge () {
      const badges = this.tags && this.tags.length ? this.tags.filter(tag => tag.includes('badge_')) : []
      if (badges.length) {
        return {
          name: badges[0],
          src: window.FILE_CDN + badges[0] + '.png'
        }
      }
    },
    gtmPrice() {
      if (this.activeVariant.price) return formatPrice(this.activeVariant.price)

      return null
    },
  },
  mounted () {
    if (this.optionsWithValues) {
      this.selectedOptions = this.optionsWithValues.reduce((obj, option) => {
        if (this.activeColor && option.name.toLowerCase() === 'color') {
          obj[option.name] = this.activeColor
        } else {
          obj[option.name] = ''
        }
        return obj
      }, {})
    }

    on(window, 'scroll', this.scrollHandler)

    // Review style
    forceRefreshYotpoWidgets()

    const productCardReviews = this.$refs.productCardReviews
    if (productCardReviews && productCardReviews.tagName) {
      const observer = new window.MutationObserver((mutations) => {
        for (let mutation of mutations) {
          if (mutation.type === 'childList') {
            appState.productReviewsLoaded = true
          }
        }
      })

      observer.observe(productCardReviews, {
        childList: true
      })
    }
  },
  methods: {
    getProductFullName,
    addToCart () {
      this.isProcessing = true
      const properties = {
        _item_list_id: this.listAttribution ? handleize(this.listAttribution) : '',
        _item_list_name: this.listAttribution || ""
      }
      if (this.collection && this.collection.length) {
        properties['_collection'] = this.collection.join('|')
      }
      cart.add([{
        id: this.activeVariant.id,
        quantity: 1,
        properties: deepClone(properties)
      }]).then(() => {
        cart.get().then((data) => {
          state.cart = data
          trigger('mini-cart-open', document.body, data)
          this.isProcessing = false
          trigger('update-gwp', document.body)
        })
      })
      this.addToBagGTM()
      this.addKlaviyoEvent()
    },
    addKlaviyoEvent () {
      let _learnq = window._learnq || []
      const klaviyoItem = {
        Name: this.title,
        ProductID: this.id,
        Categories: this.collection,
        ImageURL: `https:${this.featuredImage}`,
        URL: `${window.location.origin}/products/${this.handle}`,
        Brand: this.vendor,
        Price: this.formattedPrice,
        CompareAtPrice: formatMoney(this.product.compare_at_price_max)
      }
      _learnq.push(['track', 'Added to Cart', klaviyoItem])
    },
    addToBagGTM() {
      const productVariantName = this.activeVariant
        ? this.activeVariant.title
        : this.product.options[0].values[0]
      const ga4Item = {
        item_id: this.product.id,
        item_name: this.getProductFullName(
          this.product.title,
          productVariantName
        ),
        index: 0,
        item_brand: this.product.vendor,
        item_list_id: this.listAttribution && handleize(this.listAttribution),
        item_list_name: this.listAttribution,
        item_variant: getVariantName(productVariantName),
        price: this.gtmPrice,
        quantity: 1,
        ...mapProductCollectionsToGa4Categories(this.product.collection),
      }

      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: this.gtmPrice,
          items: [ga4Item],
        },
      })
    },
  },
  destroyed () {
    on.off(window, 'scroll', this.scrollHandler)
  }
})
